import { Component, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CPOs, StationType, IUpdateStationDTO, ITariff } from 'common_library';
import { BehaviorSubject, debounceTime, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { IOptionPod } from 'src/app/pages/pods/pod-new/new-pod.component';
import { Station } from 'src/app/pages/stations/station-detail/station-detail.component';
import { PodService } from 'src/app/services/entities/pod.service';
import { StationService } from 'src/app/services/entities/station.service';
import { TariffService } from 'src/app/services/entities/tariff.service';
import { UserService } from 'src/app/services/entities/user.service';


@Component({
  selector: 'app-block-form-station',
  templateUrl: './block-form-station.component.html',
  styleUrls: ['./block-form-station.component.scss']
})
export class BlockFormStationComponent implements OnInit, OnDestroy {
  @Input() station$ = new BehaviorSubject<Station>(null);
  optionUsers$ = new BehaviorSubject<Array<any>>(null)
  stationForm: FormGroup;
  @Output() formData = new EventEmitter<IUpdateStationDTO>();
  @Output() cancelButton = new EventEmitter()
  sub: Subscription;
  optionType$ = new BehaviorSubject<any>(null);
  optionCpo$ = new BehaviorSubject<any>(null);
  optionPod$ = new BehaviorSubject<any>(null);

  tariffs$: Observable<ITariff[]>
  tariffSearch$ = new Subject<string>();

  constructor(
    private stationService: StationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private podService: PodService,
    private tariffService: TariffService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.tariffs$ = this.tariffSearch$.pipe(
      debounceTime(500),
      switchMap(searchText => {
        if (searchText) { //evito di fare la chiamata che chieda tutti gli elementi: restituisco array vuoto
          return this.getTariffByString(searchText);
        }
        else {
          return [];
        }
      })
    )

    this.stationForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required, Validators.minLength(1)]),
      coordinates: new FormControl(null, [Validators.required, Validators.minLength(1), this.coordinatesValidator]),
      stationType: new FormControl(null),
      cpo: new FormControl(null),
      power: new FormControl(null),
      info: new FormControl(null),
      podName: new FormControl(null),
      stationOwner: new FormControl(null),
      tariffName: new FormControl()
    });

    this.cposKeys();
    this.typeKeys();

    this.sub = this.station$.subscribe(async (station) => {
      if (station) {
        if (station.ownerId) {
          const user = await this.userService.getUserById(station.ownerId);
          this.optionUsers$.next([{ value: user.id, label: `${user.surname} ${user.name}`, id: user.id }]);
        }
        if (station.pod) {
          const pod = station.pod
          this.optionPod$.next([{ value: pod.id, label: pod.name, id: pod.id }]);
        }
        if (station.tariffId) {
          let tariff = await this.tariffService.getTariffById(station.tariffId);
          if (tariff) {
            this.onInputTariffStation(tariff.name);
          }
        }

        const coord = station.coordinates = `${station.coord.coordinates[0].toString()}, ${station.coord.coordinates[1].toString()}`;

        this.stationForm.setValue({
          name: station?.name,
          coordinates: coord,
          stationType: station?.stationType,
          cpo: station?.cpo,
          power: station?.power,
          info: station?.info,
          podName: station?.podId,
          stationOwner: station?.ownerId,
          tariffName: station?.tariffId
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  submit() {
    const coordinates = this.stationService.coordinateFormat(this.stationForm.get('coordinates').value);
    const dto: IUpdateStationDTO = {
      name: this.stationForm.get('name').value.trim(),
      coord: coordinates,
      stationType: this.stationForm.get('stationType').value,
      cpo: this.stationForm.get('cpo').value,
      power: this.stationForm.get('power').value,
      info: this.stationForm.get('info').value,
      podId: this.stationForm.get('podName').value,
      ownerId: this.stationForm.get('stationOwner').value,
      tariffId: this.stationForm.get('tariffName').value
    }
    this.formData.emit(dto)
  }

  async onInputPodName(event) {
    const value = event;
    if (value?.length >= 3) {
      await this.getPodByString(value);
    } else {
      this.optionPod$.next(null);
    }
  }

  async onInputUserName(event) {
    const value = event;
    if (value?.length >= 3) {
      await this.getOwnersByString(value);
    } else {
      this.optionUsers$.next(null);
    }
  }

  async onInputTariffStation(event) {
    this.tariffSearch$.next(event);
  }

  async getTariffByString(stringToFind: string): Promise<ITariff[]> {
    return await this.tariffService.getTariffsByString(stringToFind);
  }

  async getPodByString(stringToFind: string): Promise<Observable<any[]>> {
    if (!!stringToFind && typeof stringToFind === 'string') {
      const result = await this.podService.getPodsByString(stringToFind);
      let optionPods: Array<IOptionPod> = [];
      result.forEach((item) => {
        optionPods.push({ value: item.id.toString(), label: `${item.name}`, id: item.id.toString() })
      })
      this.optionPod$.next(optionPods)
      return of(result);
    }
    this.optionPod$.next(null)
  }

  async getOwnersByString(stringToFind: string): Promise<Observable<any[]>> {
    if (!!stringToFind && typeof stringToFind === 'string') {
      const result = await this.userService.getUsersByString(stringToFind);
      let optionUsers: Array<IOptionPod> = [];
      result.forEach((item) => {
        optionUsers.push({ value: item.id, label: `${item.name} ${item.surname} - ${item.email}`, id: item.id })
      })
      this.optionUsers$.next(optionUsers)
      return of(result);
    }
    this.optionUsers$.next(null)
  }


  cancelButtonClicked() {
    this.cancelButton.emit();
  }
  private cposKeys() {
    const options = Object.keys(CPOs).map((key) => {
      return {
        value: CPOs[key],
        label: key
      };
    });
    this.optionCpo$.next(
      options
    )
    return options;
  }

  private typeKeys() {
    const options = Object.keys(StationType).map((label) => {
      return {
        label,
        value: StationType[label as keyof typeof StationType],
      };
    });
    this.optionType$.next(
      options
    )
    return options;
  }

  coordinatesValidator(parameter) {
    const value = parameter.value
    if (value) {
      if (!value.includes(',')) {
        return { non: true };
      }
    }
    return null
  }
}
