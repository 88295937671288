import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CU, IInstallation } from 'common_library';
import ColorHash from 'color-hash'

@Component({
  selector: 'app-block-installation-readonly',
  templateUrl: './block-installation-readonly.component.html',
  styleUrls: ['./block-installation-readonly.component.scss']
})
export class BlockInstallationReadonlyComponent implements OnInit {
  _utilityFuncs = CU;
  colorHash = new ColorHash;
  @Input() installation: IInstallation;
  @Input() isRefreshActive: boolean = false;
  @Input() sessionStart;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  sendEvent(){
    this.updateClicked.emit();
  }
  getColorHash(text: string) {
    return this.colorHash.hex(text);
  }
}
