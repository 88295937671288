import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  IAuthState,
  IChangePasswordDTO,
  IError,
  ILoginDTO,
  IOnlyEmailDTO,
  IRefreshTokenDTO,
  IRegisterDTO,
  IUser,
} from 'common_library';
import { BehaviorSubject, fromEvent, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { _ } from 'src/app/consts';
import { StorageService } from '../storage.service';
import { HttpIoService } from '../communication/http-io.service';
import { Router } from '@angular/router';

const CONTROLLER_ROOT = "auth";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  user$ = new BehaviorSubject<IUser>(null);
  _authState:IAuthState; 

  constructor(
    private store: StorageService,
    //private http: HttpClient,
    private app: AppService,
    private HIO: HttpIoService,
    private router: Router,
  ) {
    this._authState = this.store.get<IAuthState>(_.AUTHSTATE_KEY);

    interval(10*60*1000).subscribe(this.refresh.bind(this));
  }

  get state(): IAuthState {
    return this._authState;
  }


  get isAuthenticated(): boolean {
    return !!this.state;
  }

  set state(value: IAuthState) {
    this._authState = value;
    if(!!value) {
      this.store.set(_.AUTHSTATE_KEY, value);
    } else {
      this.store.remove(_.AUTHSTATE_KEY);
    }
    this.user$.next(value?.user)
  }

  async refresh() {
    if(!!this.state) {
      try {
        //console.log("REFRESH TOKEN",this.authState.refreshToken.substring(this.authState.refreshToken.length-4));
        const dto: IRefreshTokenDTO = { refreshToken:this.state.refreshToken, clientId:this.app.clientId, userAgent:navigator.userAgent  };
        const rv = await this.HIO.post<IAuthState | IError,IRefreshTokenDTO>('auth/refresh',dto);
        if('code' in rv) {
          console.log("REFRESH TOKEN FAILED",rv);
          this.state = null;
          this.router.navigate(['/login']);
        } else {
          console.log("REFRESH TOKEN SUCCESS",rv.refreshToken.substring(rv.refreshToken.length-4));
          this.state = rv;
        }
      }
      catch(err) {
        console.log('refresh error', err);
      }
    } 
  }

  // async checkAuthAndPlanRefresh() {
  //   if (!this.state) {
  //     return;
  //   }

  //   const accessExpired = this.jwtHelper.isTokenExpired(this.state.accessToken);
  //   if (accessExpired) {
  //     return this.refreshAuth();
  //   }

  //   this.planRefresh(this.state.accessToken);
  //   this.user$.next(this.state.user);
  // }

  // async refreshAuth() {
  //   if (this.state) {
  //     // Timeout necessario altrimenti -> Error: providers are not ready yet
  //     /*if (this.state.isSocial) this.refreshSocial();
  //     else*/
  //     await this.refreshStandard(this.state.refreshToken);
  //   }
  // }

  // private planRefresh(accessToken: string) {
  //   const timeoutEdge = 600000; // 10 minuti
  //   const tokenExpirationDate: Date =
  //     this.jwtHelper.getTokenExpirationDate(accessToken);
  //   const tokenExpTimestamp = tokenExpirationDate.getTime();
  //   const nowTimestamp = new Date().getTime();

  //   let timeout = tokenExpTimestamp - nowTimestamp; /* - 60 * 1000 */
  //   // console.log('🐱️ : timeout in minuti', timeout / 60000);
  //   if(timeout<=timeoutEdge){
  //     timeout = 100
  //   }else{
  //     timeout = timeoutEdge
  //   }
  //   if (this.refreshTimer) {
  //     clearTimeout(this.refreshTimer);
  //   }

  //   this.refreshTimer = setTimeout(() => {
  //     this.refreshAuth();
  //   }, timeout);
  // }

  // private stopRefreshTokenTimer() {
  //   clearTimeout(this.refreshTimer);
  // }

  // async login(email: string, password: string): Promise<IUser> {
  //   // console.log('🐱️ : login STANDARD');
  //   try {
  //     const dto: ILoginDTO = {
  //       email,
  //       password,
  //       clientId: this.app.clientId
  //     };
  //     const state = await this.HIO.post<IAuthState, ILoginDTO>(`${CONTROLLER_ROOT}/login-admin`, dto);
  //     this.updateState(state);
  //     return state.user;
  //   } catch (error) {
  //     console.error('🐱️ : error', error);
  //     this.throwError(error);
  //   }
  // }

  async login(email: string, password: string): Promise<IUser> {
    try {
      const dto: ILoginDTO = { email, password, clientId: this.app.clientId, userAgent:navigator.userAgent };
      this.state = await this.HIO.post<IAuthState,ILoginDTO>('auth/login-admin', dto);
      return this.state?.user;
    } catch (error) {
      console.log('login error', error);
    }
  }

  // per ora il silentLogout è identico al logout
  async silentLogoutIfLogged() {
    await this.logout();
  }


  async logout(): Promise<void> {
    try {
      if (!!this.state) {
        await this.HIO.get<boolean>('auth/logout');
        this.state = null;
      }
    } catch (err) {
      console.log('logout error', err);
    }
  }

  // async refreshStandard(refreshToken: string): Promise<void> {
  //   // console.log('🐱️ : refresh STANDARD');
  //   try {
  //     const dto: IRefreshTokenDTO = { refreshToken, clientId:this.app.clientId, userAgent:navigator.userAgent };
  //     const state = await this.HIO
  //       .post<IAuthState, IRefreshTokenDTO>(`${CONTROLLER_ROOT}/refresh`, dto)
  //     this.updateState(state);
  //   } catch (error) {
  //     console.error('🐱️ : error', error);
  //     if(error.status === 401) {
  //       this.logoutAndClearState();
  //     }
  //     this.throwError(error);
  //   }
  // }
  /*
  async loginSocial() {
    // console.log('🐱️ : login SOCIAL');
    try {
      const socialUser = await this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
      return socialUser.email;
    } catch (error) {
      console.log('🐱️ : error', error);
      this.throwError(error);
    }
  }
  */
  /*
  async refreshSocial() {
    // console.log('🐱️ : refresh SOCIAL');
    try {
      await this.loading.presentLoading({ message: 'Login in corso' });
      setTimeout(() => this.socialAuth.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID), 2000);
    } catch (error) {
      console.log('🐱️ : error', error);
      this.logoutAndClearState();
      this.throwError(error);
    }
  }
  */
  /*
  async updateSocial(socialUser: SocialUser): Promise<IUser> {
    // console.log('🐱️ : updateSocial');
    try {
      const dto: ISocialLoginDTO = {
        token: socialUser.idToken,
        social: socialUser.provider,
        clientId: this.app.clientId,
      };
      const state = await this.http
        .post<IAuthState>(`${this.app.apiUrl}/auth/social-login`, dto)
        .toPromise();
      this.updateState(state);
      return this.state.user;
    } catch (error) {
      console.log('🐱️ : error', error);
      this.throwError(error);
    }
  }
  */
  // async logout(): Promise<void> {
  //   try {
  //     if (this.state) {
  //       await this.HIO
  //         .get<boolean>(`${CONTROLLER_ROOT}/logout`)
  //       this.logoutAndClearState();
  //     }
  //   } catch (error) {
  //     console.error('🐱️ : error', error);
  //     this.throwError(error);
  //   }
  // }


  // updateState(state: IAuthState) {
  //   this.state = state;
  //   this.user$.next(this.state?.user);
  //   if (state) {
  //     this.planRefresh(this.state.accessToken);
  //   } else {
  //     this.stopRefreshTokenTimer();
  //     this.store.removeLocalStorage(_.AUTHSTATE_KEY);
  //   }
  // }

  // async logoutAndClearState() {
  //   this.updateState(null);
  // }

  // throwError(error: HttpErrorResponse) {
  //   let message: string;
  //   switch (error.status) {
  //     case 401:
  //       message = 'Credenziali Errate. Effettuare il login.';
  //       break;
  //     case 409:
  //       message = error.error.message;
  //       break;
  //     case 500:
  //       message = 'Errore Server';
  //       break;
  //     case 0:
  //       message = 'Impossibile raggiungere il server';
  //       break;
  //   }
  //   throw new Error(message);
  // }
}
