import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    LoginRoutingModule,
    FormsModule,
    NzLayoutModule,
    HttpClientModule,
    NzFormModule,
    NzInputModule,
    ReactiveFormsModule,
    NzNotificationModule,
    NzButtonModule,
    NzIconModule,
    TranslateModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
})
export class LoginModule {}
