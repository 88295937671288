import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CU, IConnector, ISession, StateValues } from 'common_library';
import { NzMessageService } from 'ng-zorro-antd/message';
import { SessionService } from 'src/app/services/entities/session.service';

@Component({
  selector: 'app-station-connectors',
  templateUrl: './station-connectors.component.html',
  styleUrls: ['./station-connectors.component.scss']
})
export class StationConnectorsComponent implements OnInit, OnChanges {
  @Input() connectors: any[];
  @Output() unlockConnectorEvent: EventEmitter<IConnector> = new EventEmitter();
  @Output() stopSessionEvent: EventEmitter<ISession> = new EventEmitter();

  constructor(private sessionService: SessionService, private translate: TranslateService) { }

  ngOnInit(): void {
    console.log(this.connectors);    
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    console.log(changes);
    if (changes?.connectors) {
      // Prendo le sessioni dei connettori e le aggiungo ai connettori stessi
      for (const c of this.connectors) {
        c.session = await this.sessionService.getOneSession(c?.currentSessionId);
        c.avatar = 'assets/plugTypes/presa_' + c.plugTypes[0].toLowerCase() + '.svg';
        c.href = '/authenticated/stations/' + c.stationId;
      }
      console.log(this.connectors, changes.connectors);
    }
  }

  unlockConnector(connector: IConnector): void {
    console.log(connector);
    this.unlockConnectorEvent.emit(connector);
  }

  stopSession(session: ISession): void {
    console.log(session);
    this.stopSessionEvent.emit(session);
  }

  getTitle(c: IConnector): string {
    return c.name ? c.name : " #" + c.id
  }
  getColorByState(state: StateValues) {
    return CU.getColorByState(state);
  }
  now(){
    return new Date();
  }
}
