import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CU, ISession, RecordCounted, RevenueEntity } from 'common_library';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { SessionService } from 'src/app/services/entities/session.service';
import { StorageService } from '../../services/storage.service';
import { AppService } from 'src/app/services/app.service';
import { DatePickerComponent } from 'src/app/components/date-picker/date-picker.component';
import { _ } from 'src/app/consts';
import { TranslateService } from '@ngx-translate/core';

const CONTROLLER_ROOT = 'session';
export const LOCAL_STORAGE_SESSIONS = 'select_sessions_state';
export const LOCAL_STORAGE_DATE_RANGE = 'select_date_range';

export enum SELECT_DATE_RANGE {
  CUSTOM_RANGE = 'customRange',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'thisWeek',
  LAST_WEEK = 'lastWeek',
}

export enum SELECT_SESSIONS {
  ALL = 'all',
  ACTIVE = 'active',
  NOT_ACTIVE = 'not-active',
}

interface ISessionSearchCriteria {
  sessionState: SELECT_SESSIONS,
  dateRange?: Date[],
  pageIndex?: number,
  dateRangeAsText?: SELECT_DATE_RANGE
}
@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnInit {
  sessions: ISession[];
  utilityFuncs = CU;
  activeSessionsInterval;
  startDate: Date;
  endDate: Date = new Date();
  form = new FormGroup({});
  spinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  model = {
    actives: this.LS.get(_.SESSION_SEARCH_CRITERIA) ? (this.LS.get(_.SESSION_SEARCH_CRITERIA) as ISessionSearchCriteria).sessionState : SELECT_SESSIONS.ACTIVE,
    dateRange: this.LS.get(_.SESSION_SEARCH_CRITERIA) ? (this.LS.get(_.SESSION_SEARCH_CRITERIA) as ISessionSearchCriteria).dateRangeAsText : SELECT_DATE_RANGE.TODAY,
  };
  formSub: Subscription;

  fields: FormlyFieldConfig[] = this.getUpdatedFields();
  searchInProgress: boolean = false;
  dateRange: Date[];
  allSessionCount: number;
  currentIndex = 1;

  sessionSearchCriteria$ = new BehaviorSubject<ISessionSearchCriteria>(null);
  sessions$: Observable<RecordCounted<ISession>>;

  private langChangeSubscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private LS: StorageService,
    public AS: AppService,
    private translate: TranslateService
  ) { 
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
    });
  }

  async ngOnInit() {
    let sessionStorage: ISessionSearchCriteria = this.LS.get(_.SESSION_SEARCH_CRITERIA);
    if (!sessionStorage) {
      const dateRange = this.sessionService.dateCalcToArrayOfDates(SELECT_DATE_RANGE.TODAY)
      sessionStorage = { sessionState: SELECT_SESSIONS.ACTIVE, pageIndex: 1, dateRange }
      this.LS.set(_.SESSION_SEARCH_CRITERIA, sessionStorage);
    }
    this.sessionSearchCriteria$.next(sessionStorage);
    this.sessions$ = this.sessionSearchCriteria$.pipe(switchMap((searchCriteria) => {
      this.LS.set(_.SESSION_SEARCH_CRITERIA, searchCriteria);
      return this.sessionService.getSessionsByDate$(searchCriteria);
    }))
  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'actives',
        type: 'select',
        className: 'selectStyle',
        defaultValue: 'all',
        props: {
          options: [
            { label: this.translate.instant("SESSION.MAIN.ACTIVE_SESSION"), value: SELECT_SESSIONS.ACTIVE },
            { label: this.translate.instant("SESSION.MAIN.NON_ACTIVE_SESSION"), value: SELECT_SESSIONS.NOT_ACTIVE },
          ],
          change: async (value) => {
            const searchCriteria: ISessionSearchCriteria = {
              sessionState: value.formControl.value as SELECT_SESSIONS,
              pageIndex: 1,
              dateRange: this.sessionService.dateCalcToArrayOfDates(SELECT_DATE_RANGE.TODAY),
              dateRangeAsText: SELECT_DATE_RANGE.TODAY
            }
            this.sessionSearchCriteria$.next(searchCriteria);
          }
        }
      },
      {
        key: 'dateRange',
        type: 'select',
        className: 'selectStyle',
        defaultValue: SELECT_DATE_RANGE.TODAY,
        props: {
          options: [
            { label: this.translate.instant("SESSION.MAIN.TODAY"), value: SELECT_DATE_RANGE.TODAY },
            { label: this.translate.instant("SESSION.MAIN.YESTERDAY"), value: SELECT_DATE_RANGE.YESTERDAY },
            { label: this.translate.instant("SESSION.MAIN.CURRENT_WEEK"), value: SELECT_DATE_RANGE.THIS_WEEK },
            { label: this.translate.instant("SESSION.MAIN.LAST_WEEK"), value: SELECT_DATE_RANGE.LAST_WEEK },
            { label: this.translate.instant("SESSION.MAIN.PERSONALIZED_RANGE"), value: SELECT_DATE_RANGE.CUSTOM_RANGE },
          ],
          change: async (value) => {
            this.sessionSearchCriteria$.next({
              pageIndex: 1,
              sessionState: this.sessionSearchCriteria$.getValue().sessionState,
              dateRange: this.sessionService.dateCalcToArrayOfDates(value.formControl.value as SELECT_DATE_RANGE),
              dateRangeAsText: value.formControl.value as SELECT_DATE_RANGE
            });
          },
        },
        expressions: {
          hide: "model.actives == 'active'",
        },
      },
      {
        key: 'datePicker',
        type: DatePickerComponent,
        expressions: {
          hide: (): boolean => {
            if (this.model.actives === SELECT_SESSIONS.ACTIVE || this.form.value.dateRange !== SELECT_DATE_RANGE.CUSTOM_RANGE) {
              return true;
            }
          }
        },
        props: {
          date: this.getDates()
        },
        hooks: {
          onInit: (field) => {
            field.formControl.valueChanges.subscribe((value) => {
              this.onEventByDateRange(value);
            });
          },
        }
      }
    ];
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  private isTooMuchDays(days: number): boolean {
    return days > 30 ? true : false;
  }

  async onEventByDateRange(range: Date[]) {
    if (range && range.length === 2) {
      if (this.isTooMuchDays(this.utilityFuncs.getDayDiff(range[1], range[0]))) {
        this.sessionSearchCriteria$.next({ ...this.sessionSearchCriteria$.getValue(), dateRange: undefined })
        this.AS.createNotification('error', this.translate.instant("PHRASE.ERROR"), this.translate.instant("PHRASE.INVALID_DATE"));
      } else {
        this.sessionSearchCriteria$.next({
          ...this.sessionSearchCriteria$.getValue(),
          dateRange: [range[0], range[1]]
        });
      }
    } else if (range && range.length === 0) {
      this.sessionSearchCriteria$.next({ ...this.sessionSearchCriteria$.getValue(), dateRange: undefined })
    }
  }

  async refreshActiveSessions() {
    this.spinner.next(true);
    this.sessionSearchCriteria$.next({ ...this.sessionSearchCriteria$.getValue() });
    this.spinner.next(false);
  }

  pageIndexChange(page) {
    this.sessionSearchCriteria$.next({
      ...this.sessionSearchCriteria$.getValue(),
      pageIndex: page,
    })
  }

  getDates(): Date[] {
    const dateRangeFromStorage = (this.LS.get(_.SESSION_SEARCH_CRITERIA) as ISessionSearchCriteria)?.dateRange;
    return dateRangeFromStorage ? dateRangeFromStorage : [];
  }
}
