import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocsComponent } from './docs.component';
import { DocsRoutingModule } from './docs-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@NgModule({
  imports: [
    CommonModule,
    DocsRoutingModule,
    HttpClientModule,
    NzPageHeaderModule,
    NzLayoutModule
  ],
  declarations: [DocsComponent],
  exports: [DocsComponent],
  providers: []
})
export class DocsModule { }
