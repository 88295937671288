import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { lastValueFrom, Observable } from 'rxjs';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';


@Component({
  selector: 'field-autocomplete',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './field-autocomplete.component.html',
})
export class FieldAutocomplete  extends FieldType<FieldTypeConfig> implements OnInit {
  filteredOptions: string[] = [];
  constructor(){
    super()
  }
  ngOnInit(): void { }

  compareFun = (o1: any | string, o2: any): boolean => {
    if (o1) {
      return typeof o1 === 'string' ? o1 === o2.label : o1.value === o2.value;
    } else {
      return false;
    }
  };
}
