import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanLoad,
  UrlSegment,
  Route,
  ActivatedRoute,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AppService } from 'src/app/services/app.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanLoad {
  constructor(private auth: AuthService, public router: Router, private activatedRoute: ActivatedRoute, private AS: AppService) {}

  canActivate(): boolean {
    if (this.auth.state === null) {
      this.router.navigate(['/login']);
      return false;
    }
    if (this.AS.hasValueBeenCopied) {
      this.AS.hasValueBeenCopied = false;
      return false;
    }
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean {
    if(this.AS.appOff){
      return false;
    }else{
      return this.canActivate();
    }
  }
}
