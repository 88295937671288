import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CU } from 'common_library';

@Component({
  selector: 'app-list-features',
  templateUrl: './list-features.component.html',
  styleUrls: ['./list-features.component.scss']
})
export class ListFeaturesComponent implements OnInit {
  _utilityFuncs = CU
  @Input() features: {isAdmin?: boolean; disabled?: boolean; ccType?: string}

  constructor(private translate: TranslateService ) { }

  ngOnInit(): void {
  }

}
