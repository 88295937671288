import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IPod, RecordCounted } from 'common_library';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'pod';

@Injectable({ providedIn: 'root' })
export class PodService {

  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async getAllPod(): Promise<IPod[]> {
    try {
      const rv = await this.HIO.get<IPod[]>(`${CONTROLLER_ROOT}/all-for-dashboard`)
      return rv
    } catch (err) {
      console.error(err)
    }
  }

  async getPodsByString(text: string): Promise<IPod[]> {
    try {
      const rv = await this.HIO.get<IPod[]>(`${CONTROLLER_ROOT}/search/${text}`)
      return rv
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getPodById(id: number): Promise<IPod> {
    try {
      const rv = await this.HIO.get<IPod>(`${CONTROLLER_ROOT}/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
  
  async getPodByIdAdminBlock(id: number): Promise<IPod> {
    try {
      const rv = await this.HIO.get<IPod>(`${CONTROLLER_ROOT}/admin/block/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getPodByStationIdAdminBlock(stationId: string): Promise<IPod> {
    try {
      const rv = await this.HIO.get<IPod>(`${CONTROLLER_ROOT}/admin/block/by-station/${stationId}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getPodByIdNoRelations(id: number): Promise<IPod> {
    try {
      const rv = await this.HIO.get<IPod>(`${CONTROLLER_ROOT}/no-relations/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async update(id: number, dto: any): Promise<IPod> {
    try {
      return await this.HIO.patchItem<IPod, any>(`${CONTROLLER_ROOT}`, id, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async create(dto: any): Promise<IPod> {
    try {
      return await this.HIO.post<IPod, any>(`${CONTROLLER_ROOT}`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
  async getPodByOwnerId(ownerId: string): Promise<IPod[]> {
    try {
      return await this.HIO.get<IPod[]>(`${CONTROLLER_ROOT}/search-owner/${ownerId}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  getAndCountByStrignPaginated(pageNumber?: number, searchCriteria?: string): Observable<RecordCounted<IPod>> {
    return this.commonService.getAndCount$({
      controllerDir: 'filter-and-paginate',
      controllerRoot: CONTROLLER_ROOT,
      page: pageNumber,
      search: searchCriteria
    })
  }
}
