<app-block-header title="Installazione" [isRefreshActive]="isRefreshActive" icon="fund"
  (refreshRequest)="sendEvent()"></app-block-header>
<app-block-values *ngIf="installation; else noDati" [data]="installation"
  [mainFields]="['name','address', 'updatedAt', 'state', 'cpo', 'publish']">
</app-block-values>
<div>
  <ng-container *ngFor="let station of installation?.stations">
    <nz-tag [nzColor]="getColorHash(station.name)" routerLink="/authenticated/stations/{{station.id}}">
      <div style="display: flex;">
        <app-entity-tag [entity]="station.state" entityType="state"></app-entity-tag>
        <app-entity-tag [entity]="station" entityType="station"></app-entity-tag>
        <app-entity-tag [isSmallContainer]="true" [entityType]="'online'" [entity]="station.online"></app-entity-tag>
        <span class="ml-16">
          <app-entity-tag [isSmallContainer]="true" [entityType]="'visible'" [entity]="station.visible"></app-entity-tag>
        </span>
      </div>
    </nz-tag>
  </ng-container>
</div>
<ng-template #noDati><app-empty-data></app-empty-data></ng-template>