import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.scss'],
})
export class TableDataComponent {
  @Input() listOfData: any[] = [];
  @Input() thHeader: string[] = [];
  @Input() tdRowData: string[] = [];
  @Input() path: string;
  @Input() idName: string;
}
