<nz-table #TheTable [ngClass]="{ clickable:this.clickableToShowAll }" [nzData]="mainItems" nzSize="small"
  [nzShowPagination]="false" (click)="showAll()" [nzPageSize]="10000" nzTableLayout="fixed" nzEllipsis="true">

  <thead>
    <tr>
      <th nzAlign="right">{{ this.mainItem.label }}</th>
      <th nzAlign="center" nzWidth="32px"><i nz-icon [nzType]="this.mainItem.icon" nzTheme="outline"></i></th>
      <th><app-item-value [item]="this.mainItem" (click)="goToItem()"></app-item-value></th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let item of TheTable.data">
      <td nzAlign="right" class="vam">{{ item.label }}</td>
      <td nzAlign="center" class="vam"><i nz-icon [nzType]="item.icon" nzTheme="outline"></i></td>
      <td nzAlign="left" class="vam"><app-item-value [item]="item"></app-item-value></td>
    </tr>
  </tbody>

</nz-table>

<nz-drawer [nzClosable]="true" [nzVisible]="drawerVisible" nzPlacement="right" [nzTitle]="this.name"
  (nzOnClose)="closeDrawer()" [nzWidth]="!AS.isMobile ? '40%': '100%'">
  <ng-container *nzDrawerContent>

    <div *ngFor="let section of this.sections" class="section">
      <nz-table [nzData]="this.allItems[section]" nzSize="small" [nzShowPagination]="false" [nzPageSize]="10000"
        nzTableLayout="fixed" nzEllipsis="true">
        <thead>
          <tr>
            <th nzAlign="left">{{ section.toUpperCase() }}</th>
            <th nzAlign="center" nzWidth="32px"></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <ng-container *ngFor="let item of this.allItems[section]">
            <tr *ngIf="item.type!=='none'">
              <td nzAlign="right" class="vam">{{ item.label }}</td>
              <td nzAlign="center" class="vam"><i nz-icon [nzType]="item.icon" nzTheme="outline"></i></td>
              <td nzAlign="left" class="vam"><app-item-value [item]="item"></app-item-value></td>
            </tr>
          </ng-container>
        </tbody>

      </nz-table>
    </div>
  </ng-container>
</nz-drawer>