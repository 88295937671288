import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { IConnector, ISession, IStation, IUpdateStationDTO, RecordCounted } from 'common_library';
import { Point } from 'geojson';
import { Station } from 'src/app/pages/stations/station-detail/station-detail.component';
import { CommonService } from '../common.service';
import { Observable } from 'rxjs';

const CONTROLLER_ROOT = 'station';

@Injectable({ providedIn: 'root' })
export class StationService {

  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async update(stationId: string, dto: any): Promise<IStation> {
    try {
      return await this.HIO.patchItem<IStation, IUpdateStationDTO>(`${CONTROLLER_ROOT}`, stationId, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getStationByConnectorId(id: string) {
    return (await this.HIO.get<IConnector>(`connector/${id}`)).station
  }

  async getAllStation(): Promise<IStation[]> {
    try {
      const rv = await this.HIO.get<IStation[]>(`${CONTROLLER_ROOT}/allForDashboard`)
      return rv
    } catch (err) {
      console.error(err)
    }
  }

  async getAllStationOrphans(): Promise<IStation[]> {
    try {
      const rv = await this.HIO.get<IStation[]>(`${CONTROLLER_ROOT}/orphans`)
      return rv
    } catch (err) {
      console.error(err)
    }
  }

  async getStationsByStringNotMaster(stringToFind: string, stationSelectedId?: string) {
    const result = await this.HIO.get<IStation[]>(`${CONTROLLER_ROOT}/search/not-is-master-station?search=${stringToFind}&selected=${stationSelectedId}`);
    return result
  }

  async getSessionsByConnectorId(id: number): Promise<ISession[]> {
    return (await this.HIO.get<ISession[]>(`session/all-connector/${id}`))
  }
  async getStationsByGatewayId(id: number): Promise<IStation[]> {
    return await this.HIO.get<IStation[]>(`${CONTROLLER_ROOT}/gateway/${id}`);
  }
  coordinateFormat(value) {
    let coordSplitted = value.split(",")
    const coordinates: Point = {
      type: 'Point',
      coordinates: [coordSplitted[0], coordSplitted[1]]
    }
    return coordinates
  }

  async getStationsByPodId(id: number, searchString: string, pageNumber: number, pageSize: number): Promise<RecordCounted<IStation>> {
    const data = { searchCriteria: searchString, pageNumber, pageSize };
    return await this.HIO.post(`${CONTROLLER_ROOT}/admin/pod-stations/${id}`, data);
  }

  async getOneStation(id: string): Promise<IStation> {
    return await this.HIO.get<IStation>(`${CONTROLLER_ROOT}/one/${id}`);
  }

  async getOneStationAdmin(id: string): Promise<IStation> {
    return await this.HIO.get<IStation>(`${CONTROLLER_ROOT}/admin/one/${id}`);
  }

  async getOneStationAdminBlock(id: string): Promise<IStation> {
    return await this.HIO.get<IStation>(`${CONTROLLER_ROOT}/admin/block/one/${id}`);
  }

  async getOneStationAdminBlockConnector(id: string): Promise<IStation> {
    return await this.HIO.get<IStation>(`${CONTROLLER_ROOT}/admin/block/connector/one/${id}`);
  }

  getAndCountStationPaginated(pageNumber?: number, searchString?: string): Observable<RecordCounted<Station>> {
    return this.commonService.getAndCount$({
      controllerDir: 'filter-and-count',
      controllerRoot: CONTROLLER_ROOT,
      page: pageNumber,
      search: searchString
    });
  }
}
