import { ChangeDetectorRef, Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, from, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { ITariff } from 'common_library';

@Component({
  selector: 'tariff-autocomplete',
  template: `
  <nz-form-item>
      <nz-form-label>{{ to.label }}</nz-form-label>
      <nz-form-control>
        <input
          nz-input
          [nzAutocomplete]="auto"
          [formControl]="control"
          [placeholder]="placehold"
        />
        <nz-autocomplete #auto [compareWith]="compareWith">
          <nz-auto-option *ngFor="let option of filteredOptions | async"
                          [nzValue]="option"
                          (selectionChange)="onSelectionChange($event)">
                          {{ option.name ? option.name : option.id }}
          </nz-auto-option>
        </nz-autocomplete>
      </nz-form-control>
    </nz-form-item>
  `,
})
export class TariffAutoComplete extends FieldType {
  filteredOptions: Observable<ITariff[]>;
  selectedTariff: ITariff;
  filters: boolean = false;
  entity;
  placehold;
  constructor(private http: HttpIoService, private cdr: ChangeDetectorRef) {
    super();
  }

  get control(): FormControl {
    return this.getFormControl();
  }

  getFormControl(): FormControl {
    return this.formControl as FormControl;
  }

  compareWith(o1: ITariff, o2: ITariff) {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  ngOnInit(): void {
    this.filteredOptions = this.form.valueChanges
      .pipe(
        debounceTime(1000),
        tap(value => {
          this.filters = !!value;
          if (typeof value === 'string') this.filter(value);
        })
      );
    if (!!this.model?.tariffId) {
      this.entity = this.model
    }
    this.control.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.filters = !!value;
      this.filter(value);
    });
    this.props?.entity?.subscribe(entity => {
      this.entity = entity
      if (!!entity?.tariffId) this.loadTariffById(this.entity?.tariffId);
      if (!entity?.tariffId) this.loadRecentTariffs();
    });

    this.placehold = this.props?.placeholder;

    if (!!this.entity?.tariffId) {
      this.loadTariffById(this.entity?.tariffId);
    } else if (!this.filters) {
      this.loadRecentTariffs();
    }
  }

  loadTariffById(tariffId: number) {
    const tariff = from(this.http.get<ITariff>(`tariff/search-id/${tariffId}`));
    tariff.subscribe(
      tariff => {
        this.control.setValue(tariff.name ? tariff.name : `Id: ${tariff.id}`/* , { emitEvent: false } */);
      }
    );
  }

  onSelectionChange(event: any) {
    if (event.isUserInput) {
      let selectedTariff = event.source.nzValue;
      this.control.setValue(selectedTariff.name, { emitEvent: false });
    }
  }

  loadRecentTariffs() {
    this.filteredOptions = from(this.http.get<ITariff[]>('tariff/recently-updated')).pipe(
      catchError((error: any) => {
        console.error(error);
        return of([]);
      })
    );
    this.cdr.detectChanges();
  }

  private filter(value: string) {
    if (!value) {
      return of([]);
    }
    this.filteredOptions = from(this.http.get<ITariff[]>(`tariff/search/${value}`))
      .pipe(catchError((error: any) => {
        console.error(error);
        return of([]);
      }));

    this.cdr.detectChanges();
  }
}
