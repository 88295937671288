<app-block-header title="{{'SESSION.DETAIL.SESSION_EVOLUTION'|translate}}"
                  [isRefreshActive]="isRefreshActive"
                  icon="bars"
                  (refreshRequest)="sendEvent()">
</app-block-header>
<div *ngIf="statusNotifications?.length>0; else noDati">
  <div class="container">
    <nz-steps [nzCurrent]="0" nzProgressDot nzDirection="vertical" nzSize="small">
      <nz-step *ngFor="let item of statusNotifications" [nzTitle]="item.status" nzDescription="{{ item.createdAt | localizedDate:'medium' }}"></nz-step>
    </nz-steps>
  </div>
</div>
<ng-template #noDati>
  <app-empty-data></app-empty-data>
</ng-template>
