import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from 'common_library';
import { UserService } from 'src/app/services/entities/user.service';

@Component({
  selector: 'app-block-users',
  templateUrl: './block-users.component.html',
  styleUrls: ['./block-users.component.scss']
})
export class BlockUsersComponent implements OnChanges {
  @Input() isRefreshActive: boolean = false;
  @Input() userId: string;
  @Output() updateClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  user: IUser;
  loading: boolean = false;

  constructor(private router: Router, private userService: UserService) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.userId.currentValue) {
      await this.getItem();
    }
  }

  async getItem() {
    this.loading = true;
    if (this.userId) {
      this.user = await this.userService.getOneUserAdminBlock(this.userId);
    }
    this.loading = false
  }

  async refreshItem() {
    await this.getItem();
  }

  goToUserDetail() {
    this.router.navigate([`authenticated/users/${this.user.id}`])
  }
}
