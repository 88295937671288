import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnergyManagement, State, getStateLabelByNumber } from 'common_library';
import { environment } from 'src/environments/environment';

export enum Color {
  GREY = 'grey',
  GREEN = 'green',
  YELLOW = 'yellow',
  BLUE = 'blue',
  RED = 'red',
}

const enum Entity {
  USER = 'user',
  STATION = 'station',
  CONNECTOR = 'connector',
  POD = 'pod',
  GROUP = 'group',
  USERGROUP = 'usergroup',
  STATIONGROUP = 'stationgroup',
  INSTALLATION = 'installation',
  STATE = 'state',
  GATEWAY = 'gateway',
  SECTION = 'section',
  CHAPTER = 'chapter',
  MULTIMEDIA = "multimedia",
  SESSION = "session",
  CDR = "cdr",
  TARIFF = "tariff",
  BANK_TRANSACTION = "bankTransaction",
  STATEONLY = "stateonly",
}
export const StateColors = {
  0: Color.GREY,
  1: Color.GREEN,
  2: Color.YELLOW,
  3: Color.BLUE,
  4: Color.RED,
}

@Component({
  selector: 'app-entity-tag',
  templateUrl: './entity-tag.component.html',
  styleUrls: ['./entity-tag.component.scss']
})
export class EntityTagComponent implements OnInit {

  baseURL = environment.baseURL;
  loading = false
  @Input() label?: string;
  @Input() entity?: any; // nel caso dello stato, e il valore (1,2,3,4) stesso.
  @Input() entityType: string;
  @Input() stateGroupValue?: boolean;
  @Input() routing?: boolean = false;
  @Input() entityId: number | string;
  @Input() connector: boolean = false;
  @Input() isSmallContainer: boolean = false;
  _label;
  _entity;
  _entityType;
  _id;

  dictStateColor = {
    0: Color.GREY,
    1: Color.GREEN,
    2: Color.YELLOW,
    3: Color.BLUE,
    4: Color.RED,
  }

  dictEnergyManagement = {
    0: EnergyManagement.None,
    1: EnergyManagement.Balanced,
    2: EnergyManagement.AlmostBalanced,
    3: EnergyManagement.FirstStarted,
  };

  constructor(public router: Router, private translate: TranslateService) { }

  async ngOnInit() {
    this.loading = true
    this._label = this.label || this.entity?.name + ' ' + this.entity?.surname;
    this.loading = false;
    this.setId();
  }

  navigate(event: Event) {
    event.stopPropagation();
    switch (this.entityType) {
      case Entity.USER:
        this.router.navigate([`/authenticated/users`, this._id]);
        break;
      case Entity.STATION:
        this.setId();
        this.router.navigate([`/authenticated/stations`, this._id]);
        break;
      case Entity.CONNECTOR:
        this.setId();
        this.router.navigate([`/authenticated/stations`, this.entity?.stationId]);
        break;
      case Entity.STATE:
        this.router.navigate([`/authenticated/legend`])
        break;
      case Entity.GROUP:
      case Entity.STATIONGROUP:
      case Entity.USERGROUP:
        this.router.navigate([`/authenticated/groups/editor`, this._id])
        break;
      case Entity.GATEWAY:
        this.router.navigate([`/authenticated/gateway`, this._id])
        break;
      case Entity.POD:
        this.router.navigate([`/authenticated/pods`, this._id])
        break;
      case Entity.INSTALLATION:
        this.router.navigate([`/authenticated/installations`, this._id])
        break;
      case Entity.SECTION:
        this.router.navigate([`/authenticated/info-section`, this._id])
        break;
      case Entity.CHAPTER:
        this.router.navigate([`/authenticated/info-chapter`, this._id])
        break;
      case Entity.MULTIMEDIA:
        this.router.navigate([`/authenticated/info-multimedia`, this._id])
        break;
      case Entity.SESSION:
        this.router.navigate([`/authenticated/sessions`, this._id])
        break;
      case Entity.CDR:
        this.router.navigate([`/authenticated/charge-detail-record`, this._id])
        break;
      case Entity.TARIFF:
        this.router.navigate([`/authenticated/tariffs/editor`, this._id])
        break;
      case Entity.BANK_TRANSACTION:
        this.router.navigate([`/authenticated/payments`, this._id])
        break;
      case Entity.STATEONLY:
        this.router.navigate([`/authenticated/legend`])
        break;
      default:
        break;
    }
  }
  setId() {
    this._id = this.entityId ? this.entityId : this.entity?.id
  }

  getTooltipTitleConnection(entity): string {
    return entity
      ? this.translate.instant('NZTOOLTIPTITLE.ACTIVE_CONNECTION')
      : this.translate.instant('NZTOOLTIPTITLE.NO_CONNECTION');
  }

  getTooltipTitleVisible(entity): string {
    return entity
      ? this.translate.instant('NZTOOLTIPTITLE.VISIBLE')
      : this.translate.instant('NZTOOLTIPTITLE.LIMITED_VISIBLE');
  }

  getTooltipTitleReservation(entity): string {
    return entity
      ? this.translate.instant('NZTOOLTIPTITLE.RESERVATION_ENABLED')
      : this.translate.instant('NZTOOLTIPTITLE.RESERVATION_NOT_ENABLED');
  }

  getStateLabel(entity) {
    return this.translate.instant('STATES.' + getStateLabelByNumber(entity));
  }
}
