<div class="installationDetail" [ngClass]="app.isMobile ?'mapFixedHeight': 'mapHeight' ">
  <ng-container *ngIf="installation$ | async as installation">
    <nz-card [nzBodyStyle]="getColorByState(installation.state)">
      <nz-card-meta [nzTitle]="installation.name" [nzDescription]="installation.address"
        [nzAvatar]="installationIcon"></nz-card-meta>
      <ng-container *ngFor="let station of installation.stations">
        <nz-card [nzActions]="[softReset, hardReset, toDetail]" class="station-card">
          <nz-card-meta [nzTitle]="station.name" [nzDescription]="connector" [nzAvatar]="stationIcon"></nz-card-meta>
          <ng-template #connector>
            <div class="plugs">
              <app-station-connectors [connectors]="station.connectors" (unlockConnectorEvent)="unlockConnector($event)"
                (stopSessionEvent)="stopSession($event)"></app-station-connectors>
            </div>
          </ng-template>
          <ng-template #stationIcon>
            <app-entity-tag [entityType]="'stateonly'" [entity]="station.state">
            </app-entity-tag>
          </ng-template>
          <ng-template #softReset>
            <span nz-icon class="icon-danger" nzType="poweroff" nzTheme="outline" nzTooltipTitle="{{'PHRASE.SOFT_RESET'|translate}}"
              nzTooltipPlacement="top" nz-tooltip nz-popconfirm nzPopconfirmTitle="{{'PHRASE.CONFIRM_STATION_SOFT_RESET'|translate}}"
              (nzOnConfirm)="stationSoftReset(station.id)" nzPopconfirmPlacement="top" nzOkText="{{'PHRASE.CONFIRM'|translate}}">
            </span>
          </ng-template>
          <ng-template #hardReset>
            <span nz-icon class="icon-danger" nzType="redo" nzTheme="outline" nzTooltipTitle="{{'PHRASE.HARD_RESET'|translate}}"
              nzTooltipPlacement="top" nz-tooltip nz-popconfirm nzPopconfirmTitle="{{'PHRASE.CONFIRM_STATION_HARD_RESET'|translate}}"
              (nzOnConfirm)="stationHardReset(station.id)" nzPopconfirmPlacement="top" nzOkText="{{'PHRASE.CONFIRM'|translate}}"></span>
          </ng-template>
          <ng-template #toDetail>
            <span nz-icon class="icon" nzType="eye" nzTheme="outline" nzTooltipTitle="{{'PHRASE.STATION_DETAIL'|translate}}"
              nzTooltipPlacement="top" nz-tooltip routerLink="/authenticated/stations/{{station.id}}"></span>
          </ng-template>
        </nz-card>
      </ng-container>
    </nz-card>
    <ng-template #installationIcon>
      <app-entity-tag [entityType]="'stateonly'" [entity]="installation.state">
      </app-entity-tag>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="installations$ | async as installations">
    <div *ngFor="let installation of installations">
      <nz-card class="yf-pointer" [nzBodyStyle]="getColorByState(installation.state)"
        (click)="selectInstallation(installation)">
        <nz-card-meta [nzTitle]="installation.name" [nzDescription]="installation.address"
          [nzAvatar]="installationIcon"></nz-card-meta>
      </nz-card>
      <ng-template #installationIcon>
        <app-entity-tag [entityType]="'stateonly'" [entity]="installation.state">
        </app-entity-tag>
      </ng-template>
    </div>
  </ng-container>
</div>