import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent {
  @Input() title: string;
  @Input() header: string[] = [];
  @Input() data: any = [];
  @Input() rowData: any[] = [];
  @Input() dict: any;

 }


