import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

import { NavigationEnd, Router } from '@angular/router';
import { _ } from 'src/app/consts';
import { AppService } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.service';
import { LOCAL_STORAGE_DATE_RANGE } from '../sessions/sessions.component';
import { LOCAL_STORAGE_SESSIONS } from '../sessions/sessions.component';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '../../services/translateConfig.service';
import { UserService } from 'src/app/services/entities/user.service';
import { Language } from 'common_library';

interface Submenu {
  active: boolean;
  sectionMenu?: boolean;
  chapterMenu?: boolean;
  contentMenu?: boolean;
  versionMenu?: boolean;
  legendaMenu?: boolean;
  multimediaMenu?: boolean;
}

export enum Path {
  Legend = 'legend',
  Versions = 'versions',
  Chapter = 'chapter',
  Sections = 'section',
  Content = 'content',
  Multimedia = 'multimedia',
}

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss',
    './../../../../node_modules/flag-icons/css/flag-icons.min.css'
  ]
})

export class AuthenticatedComponent implements OnInit {

  isCollapsed = false;
  infoSubmenu: Submenu = { active: false };
  aboutSubmenu: Submenu = { active: false };
  avatarInitials$: Observable<string>;

  SUBMENUS: Record<Path, Partial<Submenu>> = {
    [Path.Versions]: { active: false, versionMenu: true, chapterMenu: false, sectionMenu: false, legendaMenu: false, contentMenu: false, multimediaMenu: false },
    [Path.Legend]: { active: false, legendaMenu: true, chapterMenu: false, sectionMenu: false, versionMenu: false, contentMenu: false, multimediaMenu: false },
    [Path.Sections]: { active: false, sectionMenu: true, chapterMenu: false, versionMenu: false, legendaMenu: false, contentMenu: false, multimediaMenu: false },
    [Path.Chapter]: { active: false, chapterMenu: true, sectionMenu: false, versionMenu: false, legendaMenu: false, contentMenu: false, multimediaMenu: false },
    [Path.Content]: { active: false, contentMenu: true, chapterMenu: false, sectionMenu: false, versionMenu: false, legendaMenu: false, multimediaMenu: false },
    [Path.Multimedia]: { active: false, multimediaMenu: true, contentMenu: false, chapterMenu: false, sectionMenu: false, versionMenu: false, legendaMenu: false },
  };

  version: string = _.VERSION;
  selectedLanguage: Language; // Lingua predefinita
  selectedLanguageItem$ = new BehaviorSubject(null); // Lingua select item

  languages = [
    {
      code: 'it',
      label: this.translate.instant("LABEL.ITALIAN"),
      flag: 'it'
    },
    {
      code: 'en',
      label: this.translate.instant("LABEL.ENGLISH"),
      flag: 'gb'
    },


    // Aggiungi altre lingue se necessario
  ];

  constructor(
    public auth: AuthService,
    public AS: AppService,
    public router: Router,
    private storageService: StorageService,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
    private userService: UserService,
  ) {
    const savedLanguage: Language = this.getLanguageInApp();
    this.setLanguageInApp(savedLanguage);
    this.selectedLanguage = savedLanguage;
    this.selectedLanguageItem$.next(this.languages.find(c => c.code === savedLanguage));
    this.translate.use(savedLanguage);

    router.events.pipe(filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    ).subscribe((event) => {
      this.manageSubMenuStatus(event);
    })

  }

  ngOnInit(): void {
    if (this.AS.isMobile) this.isCollapsed = true;

    this.avatarInitials$ = this.auth.user$.pipe(
      filter(user => !!user),
      map((user) => {
        return user.name[0] + user.surname[0];
      }));
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
    this.storageService.remove(LOCAL_STORAGE_DATE_RANGE)
    this.storageService.remove(LOCAL_STORAGE_SESSIONS)
  }

  manageSubMenuStatus(event) {
    const activeSubmenu = Object.keys(this.SUBMENUS).find((key) => event.includes(key));
    if (activeSubmenu) {
      this.infoSubmenu = { ...this.infoSubmenu, ...this.SUBMENUS[activeSubmenu] };
      this.aboutSubmenu = { ...this.aboutSubmenu, ...this.SUBMENUS[activeSubmenu] };
    } else {
      this.infoSubmenu = { ...this.infoSubmenu, active: false, chapterMenu: false, sectionMenu: false, contentMenu: false, multimediaMenu: false };
      this.aboutSubmenu = { ...this.aboutSubmenu, active: false, legendaMenu: false, versionMenu: false };
    }
  }

  onLanguageChange(langCode: Language) {
    this.translate.use(langCode);
    this.setLanguageInApp(langCode);
  }


  getLanguageInApp(): Language {
    const user = this.auth.user$.value;
    // Se l'utente ha la lingua salvata nel DB, imposto quella
    if (user?.lang?.admin) {
      return user.lang.admin;
    }
    // Altrimenti provo a vedere quella salvata nel local storage
    return this.storageService.get<string>('_YF_LANG') as Language || "en";
  }

  async setLanguageInApp(langCode: Language) {
    const user = this.auth.user$.value;

    this.storageService.set('_YF_LANG', langCode);
    this.translateConfigService.setLanguage(langCode);
    this.selectedLanguageItem$.next(this.languages.find(c => c.code === langCode));

    await this.userService.updateUser(this.auth.user$.value.id, {
      ...user,
      lang: {
        admin: langCode,
      }
    });
  }
}
