import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AppService } from './services/app.service';
import { AuthService } from './services/auth/auth.service';
import { TranslateConfigService } from './services/translateConfig.service';
import { TranslateService } from '@ngx-translate/core';
import { en_US, it_IT, NzI18nService } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  offlineEvent: Observable<Event>;
  onlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  started: boolean = false;

  constructor(
    private AS: AppService,
    public auth: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
    private i18n: NzI18nService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.i18n.setLocale(en_US);

    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      let currLang = this.translateConfigService.getCurrentLang();
      switch (currLang) {
        case 'it':
          this.i18n.setLocale(it_IT);
          break;
        default:
          this.i18n.setLocale(en_US);
          break;
      }
    }));
  }

  async ngOnInit() {
    await this.auth.refresh();
    this.handleAppConnectivityChanges();
    this.started = true;
    this.auth.user$.subscribe(console.log)
  }

  private handleAppConnectivityChanges(): void {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(async e => {
      await this.auth.refresh();
      this.AS.appOff = false;
      this.AS.createNotification('success', this.translate.instant("PHRASE.NETWORK_ONLINE"), this.translate.instant("PHRASE.COMPUTER_CONNECTED"));
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.AS.appOff = true;
      this.AS.createNotification('error', this.translate.instant("PHRASE.NETWORK_ERROR"), this.translate.instant("PHRASE.CONTENT_CANNOT_LOADED"));
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  @HostListener('click', ['$event'])
  mouseClicksOffline(event: any) {
    // Controllo se sono offline, se lo sono fermo tutto, che gia dovrebbe
    if (this.AS.appOff) {
      event.preventDefault();
      this.AS.createNotification('error', this.translate.instant("PHRASE.NETWORK_ERROR"), this.translate.instant("PHRASE.CONTENT_CANNOT_LOADED"));
    }
  }
}
