import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public AS: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.AS.state && this.AS.state.accessToken) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${this.AS.state.accessToken}` },
      });
    }
    return next.handle(req);
  }
}
