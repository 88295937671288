<nz-form-item>
  <nz-form-label  nzRequired="{{field.templateOptions.required}}">{{field.templateOptions.label}}</nz-form-label>
  <nz-form-control [nzErrorTip]="field.validators?.validator?.message">
        <input placeholder="{{field.templateOptions.placeholder}}" nz-input  [formControl]="formControl" [nzAutocomplete]="auto"/>
    <nz-autocomplete #auto [compareWith]="compareFun">
      <nz-auto-option *ngFor="let option of field.props.options |formlySelectOptions |async" [nzValue]="option" [nzLabel]="option.label">
        {{ option.label }}
      </nz-auto-option>
    </nz-autocomplete>
  </nz-form-control>
</nz-form-item>
