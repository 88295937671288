import { Injectable } from '@angular/core';
import { HttpIoService } from '../communication/http-io.service';
import { ITariff, ITariffBO, ITariffTemplate, RecordCounted } from 'common_library';
import { CommonService } from '../common.service';
import { BehaviorSubject, Observable } from 'rxjs';

const CONTROLLER_ROOT = 'tariff';

@Injectable({ providedIn: 'root' })
export class TariffService {
  public selectedTemplate = new BehaviorSubject<ITariffTemplate>(null);
  constructor(private HIO: HttpIoService, private commonService: CommonService) { }

  async getAllTariff(): Promise<ITariff[]> {
    try {
      const rv = await this.HIO.get<ITariff[]>(`${CONTROLLER_ROOT}/all-for-dashboard`)
      return rv
    } catch (err) {
      console.error(err)
    }
  }

  async getTariffsByString(text: string): Promise<ITariff[]> {
    try {
      const rv = await this.HIO.get<ITariff[]>(`${CONTROLLER_ROOT}/search/${text}`)
      return rv
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getTariffById(id: string): Promise<ITariffBO> {
    try {
      const rv = await this.HIO.get<ITariffBO>(`${CONTROLLER_ROOT}/one/${id}`);
      return rv;
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async update(id: string, dto: any): Promise<ITariffBO> {
    try {
      return await this.HIO.patchItem<ITariffBO, any>(`${CONTROLLER_ROOT}`, id, dto);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async create(dto: any): Promise<ITariffBO> {
    try {
      return await this.HIO.post<ITariffBO, any>(`${CONTROLLER_ROOT}`, dto)
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getTariffByOwnerId(ownerId: string): Promise<ITariff[]> {
    try {
      return await this.HIO.get<ITariff[]>(`${CONTROLLER_ROOT}/search-owner/${ownerId}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  getAndCountTariffsByStringPaginated(pageNumber?: number, searchCriteria?: string): Observable<RecordCounted<ITariff>> {
    return this.commonService.getAndCount$<ITariff>({
      controllerDir: 'filter-and-paginate',
      controllerRoot: CONTROLLER_ROOT,
      page: pageNumber,
      search: searchCriteria
    })
  }

  async getTariffTemplates(): Promise<ITariff[]> {
    try {
      return await this.HIO.get<ITariff[]>(`${CONTROLLER_ROOT}/get-tariff-templates`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async getTariffsByNameUnique(text: string): Promise<ITariff> {
    try {
      const rv = await this.HIO.get<ITariff>(`${CONTROLLER_ROOT}/search-by-name-unique/${text}`);
      console.log(rv)
      return rv
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }

  async checkAllowedTariffName(text: string, id?: string) {
    const rv = await this.HIO.get<ITariff>(`${CONTROLLER_ROOT}/search-by-name-unique/${text}`);
    if (!rv) {
      // nessuna tariffa con lo stesso nome
      return true;
    }
    else {
      if (rv.id === id) {
        // sto modificando la stessa tariffa
        return true
      }
    }
    return false;
  }

  async getOneTariffAdminBlock(tariffId: string): Promise<ITariff> {
    try {
      return this.HIO.get(`${CONTROLLER_ROOT}/admin/block/one/${tariffId}`);
    } catch (error) {
      console.error('🐱️ : error', error);
    }
  }
}
