import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { BACKEND_URL } from './backend_url';
import { LoginModule } from './pages/login/login.module';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { AuthenticatedModule } from './pages/authenticated/authenticated.module';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { DocsModule } from './pages/docs/docs.module';
import { InstallationService } from './services/entities/installation.service';
import { AvatarDetailModule } from './pages/avatar-detail/avatar-detail.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
registerLocaleData(localeIt);
registerLocaleData(localeEn);

// quando l'app gira nel container di produzione BACKEND_URL arriva valorizzato correttamente e comanda lui
// valorizzato correttamente vuol dire che al posto di ${BACKEND_URL} c'è un valore
// _backend_url inizia per $ vuol dire che sono in sviluppo locale
let _backend_url = BACKEND_URL;
if (_backend_url.startsWith('$')) _backend_url = environment.backendUrl;

// if (_backend_url.startsWith('$')) {
//   _backend_url = 'http://localhost:3000';
//   const mode = window.location.href.split("?")[1];
//   // http://localhost:3000/?live-dev oppure http://localhost:4200/?live-dev se usi "ng serve"
//   if(mode && mode.indexOf("live-dev") >= 0) {
//     console.log("LIVE DEV MODE (local frontend app over live develop backend)")
//     _backend_url = "https://yf-dev.eagleprojects.cloud";
//   }
// }
console.log("BACKEND URL", _backend_url);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    LoginModule,
    DocsModule,
    AuthenticatedModule,
    QuillModule.forRoot(),
    AvatarDetailModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: 'BACKEND_URL', useValue: _backend_url },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: 'SOCKET_IO_ADDRESS', useValue: _backend_url },
    InstallationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
