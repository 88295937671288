<app-block-header [isRefreshActive]="isRefreshActive"
                  title="Utente"
                  (refreshRequest)="refreshItem()"
                  icon="user">
</app-block-header>
<app-skeleton [loading]="loading">
  <app-block-values 
  *ngIf="user; else noDati" 
  [data]="user"
  (goToItemDetail)="goToUserDetail()"
  [mainFields]="['name','surname','email','isAdmin','disabled']">
</app-block-values>
</app-skeleton>
<ng-template #noDati><app-empty-data></app-empty-data></ng-template>
