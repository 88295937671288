<form nz-form [formGroup]="stationForm" (ngSubmit)="submit()" class="size container-form">
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'STATIONS.DETAIL.NAME_NO_PERSON'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.VALID_NAME'|translate}}">
      <input nz-input formControlName="name" />
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6" nzRequired>{{'STATIONS.DETAIL.COORDINATES'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.INVALID_COORDINATES'|translate}}">
      <input nz-input formControlName="coordinates">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.TYPE'|translate}}</nz-form-label>
    <nz-form-control>
      <nz-select formControlName="stationType">
        <nz-option *ngFor="let item of optionType$ | async" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.CPO_MIN'|translate}}</nz-form-label>
    <nz-form-control>
      <nz-select formControlName="cpo">
        <nz-option *ngFor="let item of optionCpo$ | async" [nzValue]="item.value" [nzLabel]="item.label"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.POWER_WATT'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.VALID_VALUE'|translate}}">
      <nz-input-number formControlName="power"></nz-input-number>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.INFO'|translate}}</nz-form-label>
    <nz-form-control>
      <input nz-input formControlName="info">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.POD'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.SELECT_VALID_POD'|translate}}">
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'PLACEHOLDER.FIND'|translate}}" formControlName="podName" [nzShowArrow]="false"
        [nzAllowClear]="true" (nzOnSearch)="onInputPodName($event)">
        <nz-option *ngFor="let option of this.optionPod$ | async" [nzLabel]="option.label"
          [nzValue]="option.value"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.OWNER'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.SELECT_VALID_USER'|translate}}">
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'PLACEHOLDER.FIND'|translate}}" formControlName="stationOwner"
        [nzShowArrow]="false" [nzAllowClear]="true" (nzOnSearch)="onInputUserName($event)">
        <nz-option *ngFor="let option of this.optionUsers$ | async" [nzLabel]="option.label"
          [nzValue]="option.value"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSpan]="6">{{'STATIONS.DETAIL.RATE'|translate}}</nz-form-label>
    <nz-form-control nzErrorTip="{{'STATIONS.DETAIL.FORM.SELECT_VALID_TARIFF'|translate}}">
      <nz-select nzShowSearch nzServerSearch nzPlaceHolder="{{'STATIONS.DETAIL.FORM.CHAR_FOR_SEARCH'|translate}}"
        formControlName="tariffName" [nzShowArrow]="false" [nzAllowClear]="true"
        (nzOnSearch)="onInputTariffStation($event)">
        <nz-option *ngFor="let tariff of tariffs$ | async" [nzLabel]="tariff.name" [nzValue]="tariff.id"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
<div nz-row>
  <div nz-col nzXs="24" nzSm="24" nzMd="24">
    <div nz-row nzJustify="end" [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }">
      <div nz-col nzXs="8">
        <button class="btn" nz-button nzBlock (click)="cancelButtonClicked()">{{'BUTTONS.CANCEL'|translate}}</button>
      </div>
      <div nz-col nzXs="8">
        <button class="btn" nz-button type="submit" nzType="primary" nzBlock (click)="submit()"
          [disabled]="stationForm.invalid">{{'BUTTONS.SAVE'|translate}}</button>
      </div>
    </div>
  </div>
</div>
<!-- <button class="btn mr-5" nz-button (click)="cancelButtonClicked()">{{'BUTTONS.CANCEL'|translate}}</button>
<button class="btn ml-5" nz-button type="submit" nzType="primary" (click)="submit()"
  [disabled]="stationForm.invalid">Salva</button> -->