import { AuthType, AuthTypeValues } from "./enums/authType.enum";
import { State, StateValues } from "./enums/state.enum";

export namespace CU {
  export const GenerateRandomString = (length: number): string => {
    let result: string = '';
    const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength: number = characters.length;
    for (var i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
  };

  export function ToArray(enumObj: Object): { id: string; name: string }[] {
    return Object.keys(enumObj).map((key) => ({ id: key, name: enumObj[key] }));
  }

  export function EnumKeys<O extends object, K extends keyof O = keyof O>(en: O): K[] {
    return Object.keys(en).filter(k => Number.isNaN(+k)) as K[];
  }

  export function EnumValues(en: any): Array<number | string> {
    return EnumKeys(en).map(k => en[k]);
  }

  export function EnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  export function itDate(date: Date = new Date()): string {
    const dt = date.toLocaleString('it-IT', { timeZone: 'Europe/Rome' });
    const yyyy = date.getFullYear();
    let mm: string = (date.getMonth() + 1).toString(); // Months start at 0!
    let dd = date.getDate().toString();
    if (dd.length === 1) dd = '0' + dd;
    if (mm.length === 1) mm = '0' + mm;
    return yyyy + '-' + mm + '-' + dd + " " + dt.split(" ")[1];
  }

  export function replaceAll(str: string, find: string, replace: string) {
    return str.split(find).join(replace);
  }

  export function isEqual(o1: any, o2: any): boolean {
    return JSON.stringify(o1) === JSON.stringify(o2);
  }

  export function isNullOrUndefined(val: any) {
    return (val === undefined || val == null) ? true : false;
  }

  export function addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
  }

  export function secsToHHMM(secs: number): string {
    return new Date(secs * 1000).toISOString().substring(11, 16)
  }

  export function idTagFromSessionId(sid: string): string {
    return replaceAll(sid, '-', '').substring(0, 14)?.toLocaleLowerCase();
  }
  export interface TimeSpan {
    d?: number, h?: number, m?: number, s?: number
  }

  export function timeSpan(fromDt: Date, toDt: Date, text?: boolean): string | Array<{ type: string, value: number }> {

    const date_start = new Date(fromDt).getTime();
    const date_end = new Date(toDt).getTime();
    const newValue: any = date_start - date_end;

    let d = Math.abs(date_end - date_start) / 1000;  // delta
    let r = {}; // result
    let s = {    // structure
      year: 31536000,
      mounth: 2592000,
      week: 604800, // uncomment row to ignore
      d: 86400,   // feel free to add your own row
      h: 3600,
      m: 60,
      s: 1

    };

    Object.keys(s).forEach(function (key) {

      r[key] = Math.floor(d / s[key]);

      d -= r[key] * s[key];

    });

    if (text) {
      return transformDate(r);
    }
    return syntheticDate(r);
  }

  function transformDate(timer): string {
    let time: Array<{ type: string, value: number }> = [];
    if (timer.d > 0) {
      time.push({
        type: 'd',
        value: timer.d
      })
      time.push({
        type: 'h',
        value: timer.h
      }
      )
      return `${timer.d} d ${timer.h} h`
    } else if (timer.h > 0) {
      time.push({
        type: 'h',
        value: timer.h
      })
      time.push({
        type: 'm',
        value: timer.m
      })
      return `${timer.h} h ${timer.m} m`
    }
    time.push({
      type: 'm',
      value: timer.m
    })
    time.push({
      type: 's',
      value: timer.s
    })
    return `${timer.m}m ${timer.s}s`
  }

  function syntheticDate(timer): Array<{ type: string, value: number }> {
    let test: Array<{ type: string, value: number }> = [];
    if (timer.d > 0) {
      test.push({
        type: 'd',
        value: timer.d
      })
      test.push({
        type: 'h',
        value: timer.h
      }
      )
      return test;
    } else if (timer.h > 0) {
      test.push({
        type: 'h',
        value: timer.h
      })
      test.push({
        type: 'm',
        value: timer.m
      })
      return test;
    }
    test.push({
      type: 'm',
      value: timer.m
    })
    test.push({
      type: 's',
      value: timer.s
    })
    return test;
  }

  export function timeSince(date: Date = new Date()) {
    const dt = new Date(date).getTime();
    let seconds = Math.floor((Date.now() - dt) / 1000);

    return humanReadableTime(seconds);
  }

  export function humanReadableTime(seconds: number): string {
    let interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " anni";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " mesi";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " giorni";
    }
    interval = seconds / 3600;
    if (interval >= 1 && interval < 2) {
      return Math.floor(interval) + " ora";
    }
    if (interval > 1) {
      return Math.floor(interval) + " ore";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minuti";
    }
    return Math.floor(seconds) + " secondi";
  }

  export function formattedCountdown(endDate: Date): string {
    const dDay = endDate.valueOf();

    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();

    const hours = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
      hoursInADay
    );

    const minutes = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
      secondsInAMinute
    );

    const seconds = Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

    if (hours < 0 || minutes < 0 || seconds < 0) return '00:00';

    const minutesPadded = minutes.toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    const secondsPadded = seconds.toLocaleString('it-IT', { minimumIntegerDigits: 2 });

    if (hours > 0) return `${hours}:${minutesPadded}:${secondsPadded}`;
    return `${minutesPadded}:${secondsPadded}`;
  }

  export function getYearMonthDescription(date: Date = new Date()): string {
    return date.toLocaleString('it-IT', { year: 'numeric', month: 'long' });
  }

  export function getDayDiff(startDate: Date, endDate: Date): number {
    const msInDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  }

  export function getDateDiffInSeconds(startDate: Date, endDate: Date): number {
    return Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / 1000);
  }

  export function getDateDiffInSecondsWithOneOnZero(startDate: Date, endDate: Date): number {
    const diffInSeconds = Math.round(Math.abs(startDate.getTime() - endDate.getTime()) / 1000);
    return diffInSeconds === 0 ? diffInSeconds + 1 : diffInSeconds;
  }

  export function getDateDiffInMinutes(startDate: Date, endDate: Date): number {
    return Math.round((startDate.getTime() - endDate.getTime()) / 60000);
  }

  export function stringIsUtcDate(text: string): boolean {
    let regexpDate = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$');
    return regexpDate.test(text);
  }

  export function calcPriceRound(value: number): number {
    return Math.round(value * 100) / 100;
  }

  export function roundNumber(value: number, places: number = 2) {
    return parseFloat(value.toFixed(places));
  }

  export function getLogoByCdcType(cdcType: string): string {
    if (cdcType === "VISA") {
      return "/assets/logos/cdc/cdc_visa.png";
    }
    if (cdcType === "MASTERCARD") {
      return "/assets/logos/cdc/cdc_mastercard.png";
    }
    return "/assets/logos/cdc/cdc_generic.png";
  }

  export function isIpAddress(txt: string): boolean {
    return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(txt));
  }

  export function formatHours(hours: number): string {
    const h = Math.floor(hours);
    const m = Math.floor((hours - h) * 60);
    const s = Math.floor(((hours - h) * 60 - m) * 60);
    return `${h ? h + 'h' : ''} ${m ? m + 'm' : ''} ${s ? s + 's' : ''}`;
  }

  export function formatDateMySond(objectDate: Date): string {
    let seconds = objectDate.getSeconds().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let minutes = objectDate.getMinutes().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let hours = objectDate.getHours().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let day = objectDate.getDate().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let month = (objectDate.getMonth() + 1).toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let year = objectDate.getFullYear();

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  export function formatDateYYYY_MM_DD_hh_mm_ss(objectDate: Date): string {
    let seconds = objectDate.getSeconds().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let minutes = objectDate.getMinutes().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let hours = objectDate.getHours().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let day = objectDate.getDate().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let month = (objectDate.getMonth() + 1).toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let year = objectDate.getFullYear();

    return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;
  }

  export function formatDateYYYY_MM_DD(objectDate: Date): string {
    let day = objectDate.getDate().toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let month = (objectDate.getMonth() + 1).toLocaleString('it-IT', { minimumIntegerDigits: 2 });
    let year = objectDate.getFullYear();

    return `${year}_${month}_${day}`;
  }

  export function getPriceWithVat(price: number, vat: number = 0.22): number {
    if (price === 0) return 0;
    if (!price) return 0;
    price += price * vat / 100;
    return price
  }

  export function calcPriceWithVat(netPrice: number, vat: number = 22): number {
    let vatMultiplier = (vat / 100) + 1;
    return netPrice * vatMultiplier;
  }

  export function calcPriceWithoutVat(priceWithVat: number, vat: number = 22): number {
    let vatDivider = (vat + 100) / 100;
    return priceWithVat / vatDivider;
  }

  export function convertNumericStrings(obj: any): any {
    if (typeof obj === 'string' && /^\d+(\.\d+)?$/.test(obj)) {
      return Number(obj);
    } else if (Array.isArray(obj)) {
      return obj.map((item) => convertNumericStrings(item));
    } else if (typeof obj === 'object') {
      const newObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          newObj[key] = convertNumericStrings(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  }

  export function dateAIsBigger(dateA: Date, dateB: Date): boolean {
    return dateA.getTime() > dateB.getTime()
  }

  export function capitalizeFirstLetter(textToTransform: string) {
    if (textToTransform) {
      return textToTransform.charAt(0).toUpperCase() + textToTransform.slice(1);
    } else return "";
  }

  export function authTypeToText(auth: AuthTypeValues): string {
    switch (auth) {
      case AuthType.APP_USER:
        return "applicazione";
      case AuthType.RFID:
        return "RFID";
      case AuthType.ADMIN:
        return "Amministratore backoffice";
      // dei seguenti casi sottostanti non ci sarebbe bisogno, ritorniamo stringa vuota per far sì che l'utente non legga "undefined"
      case AuthType.OTHER:
        return "";
      case AuthType.AD_HOC_USER:
        return "";
    }
  }

  export function getColorByState(state: StateValues): {
    'background-color': string,
    color: string
  } {
    switch (state) {
      case State.RESERVED:
        return {
          'background-color': '#c2fc84',
          color: '#0d2c40'
        };
      case State.AVAILABLE:
        return {
          'background-color': '#297eb2',
          color: '#fcfcff'
        };
      case State.CHARGING:
        return {
          'background-color': '#ffe121',
          color: '#0d2c40'
        };
      case State.OCCUPIED:
        return {
          'background-color': '#e92626',
          color: '#fcfcff'
        };
      case State.UNAVAILABLE:
        return {
          'background-color': '#a2a5ad',
          color: '#fcfcff'
        }
      default:
        return {
          'background-color': '#297eb2',
          color: '#fcfcff'
        };
    }
  }

  export function removeNestedNullUndefined(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        removeNestedNullUndefined(obj[key]);
      }
    }
  }
}
