import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { AppService } from '../app.service';

// TODO: Sostituire .toPromise() in await lastValuefrom()
@Injectable({ providedIn: 'root' })
export class HttpIoService {
  private readonly _basectrl;

  constructor(private http: HttpClient, private app: AppService) {
    this._basectrl = this.app.apiUrl.trim().endsWith('/')
      ? this.app.apiUrl.trim()
      : `${this.app.apiUrl.trim()}/`;
  }

  async patchWithoutResponse<DTO>(ctrl: string, data: DTO): Promise<void> {
    await this.http.patch(this.endPoint(ctrl), data).toPromise();
  }

  async patch<RES, DTO>(ctrl: string, data: DTO): Promise<RES> {
    return await this.http.patch<RES>(this.endPoint(ctrl), data).toPromise();
  }

  // remmato per attalmente non utilizzato
  // async postWithoutResponse<DTO>(ctrl: string, data: DTO): Promise<void> {
  //   //await this.http.post(this.endPoint(ctrl), data).toPromise();
  //   await this.post<any,DTO>(ctrl,data);
  // }

  async post<RES, DTO>(ctrl: string, data: DTO): Promise<RES> {
    return await this.post$<RES, DTO>(ctrl, data).toPromise();
  }

  post$<RES, DTO>(ctrl: string, data: DTO): Observable<RES> {
    return this.http.post<RES>(this.endPoint(ctrl), data);
  }

  async getWithoutResponse(ctrl: string): Promise<void> {
    await this.http.get(this.endPoint(ctrl)).toPromise();
  }

  async get<RES>(ctrl: string): Promise<RES> {
    return await firstValueFrom(this.http.get<RES>(this.endPoint(ctrl)));
  }

  item$<T>(ctrl: string, id: string | number): Observable<T> {
    return this.http.get<T>(`${this.endPoint(ctrl)}/${id}`);
  }

  items$<T>(ctrl: string): Observable<T[]> {
    return this.http.get<T[]>(this.endPoint(ctrl));
  }

  /**
   * Esegue una chiamata GET a {URL_API}/{ctrl}/{id}
   * @param {string} ctrl - è l'url del controller
   * @param {string} id - è l'identificativo della risorsa
   */
  async fetchItem<T>(ctrl: string, id: string | number): Promise<T> {
    return await this.item$<T>(ctrl, id).toPromise();
  }

  async fetchItems<T>(ctrl: string): Promise<T[]> {
    return await this.items$<T>(ctrl).toPromise();
  }

  async postItem<T, DTO>(ctrl: string, dto: DTO): Promise<T> {
    return await this.http.post<T>(this.endPoint(ctrl), dto).toPromise();
  }

  async patchItem<T, DTO>(ctrl: string, id: string | number, dto: DTO): Promise<T> {
    return await this.http.patch<T>(`${this.endPoint(ctrl)}/${id}`, dto).toPromise();
  }

  async deleteItem(ctrl: string, id: string | number): Promise<any> {
    await this.http.delete(`${this.endPoint(ctrl)}/${id}`).toPromise();
  }

  async deleteItemOnGroup(ctrl: string, groupID: number, id: string | number, entityType: string): Promise<any> {
    await this.http.delete(`${this.endPoint(ctrl)}/${groupID}/entity/${id}/${entityType}`).toPromise();
  }

  /**
   * This method returns the url to controller with the correct backend url, based on the app enviroment
   * 
   * @param ctrl : the path to the controller
   * @returns : correct path
   */
  public endPoint(ctrl: string): string {
    let _ctrl = ctrl.trim();
    _ctrl = _ctrl.startsWith('/') ? _ctrl.substring(1) : _ctrl;
    return `${this._basectrl}${_ctrl}`;
  }
}
