<nz-layout class="menu">
  <nz-sider class="{{AS.isMobile ? 'menu__sidebar menu__sidebar_mobile' : 'menu__sidebar' }}"
    [ngClass]="{'totally-hidden': AS.isMobile && isCollapsed}" nzWidth="200px" nzCollapsible [nzCollapsed]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <a routerLink="/authenticated/welcome">
        <img src="/assets/logos/logo_bianco_yourfill.svg" />
        <h1>YourFill <span>Backoffice</span></h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <!--DASHBOARD -->

      <li *ngIf="AS.isMobile" class="menu-trigger">
        <i class="trigger" nz-icon nzType="menu-fold"
          (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null"></i>
      </li>

      <li id="welcome" routerLinkActive="ant-menu-item-selected" nz-menu-item routerLink="/authenticated/welcome">
        <a routerLink="/authenticated/welcome" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="home" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none' : 'inline'">{{'MENU.DASHBOARD'|translate}}</span>
        </a>
      </li>
      <!--UTENTI -->
      <li id="users" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/users" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="team" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none' : 'inline'">{{'MENU.USERS'|translate}}</span>
        </a>
      </li>

      <!-- MAPPA -->
      <li id="map" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/map" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="global" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.MAP'|translate}}</span>
        </a>
      </li>

      <!--INSTALLAZIONI -->
      <li id="installations" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/installations" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="cluster" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none' : 'inline'">{{'MENU.INSTALLATIONS'|translate}}</span>
        </a>
      </li>
      <!--STAZIONI -->
      <li id="stations" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/stations" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="usb" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.STATIONS'|translate}}</span>
        </a>
      </li>
      <!--PODS -->
      <li id="pods" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/pods" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="one-to-one" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.POD'|translate}}</span>
        </a>
      </li>
      <!--SESSIONI -->
      <li id="sessions" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/sessions" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="group" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.SESSIONS'|translate}}</span>
        </a>
      </li>
      <!-- CHARGE DETAIL RECORDS -->
      <li id="charge-detail-record" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/charge-detail-record"
          (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="barcode" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.CDR'|translate}}</span>
        </a>
      </li>
      <!-- PAYMENTS -->
      <li id="payments" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/payments" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="credit-card" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.PAYMENTS'|translate}}</span>
        </a>
      </li>
      <!-- COST REVENUE SPLIT-->
      <li id="cost-revenue-split" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/cost-revenue-split"
          (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="euro-circle" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.INCOME'|translate}}</span>
        </a>
      </li>
      <!-- FATTURE -->
      <li id="invoice" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/invoice" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.BILL'|translate}}</span>
        </a>
      </li>
      <!--TARIFFE-->
      <li id="tariffs" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/tariffs" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="euro-circle" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.RATE'|translate}}</span>
        </a>
      </li>
      <!--GRUPPI -->
      <li id="groups" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/groups" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="ungroup" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.GROUPS'|translate}}</span>
        </a>
      </li>
      <!--GATEWAY -->
      <li id="gateways" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/gateway" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="apartment" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.GATEWAY'|translate}}</span>
        </a>
      </li>

      <!--INFO -->
      <li nz-submenu [nzTitle]="isCollapsed ? '' : ('MENU.CONTENTS' | translate)" nzIcon="edit"
        [nzOpen]="infoSubmenu.active">
        <ul>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/info-content"
            [nzSelected]="infoSubmenu.contentMenu">
            <i nz-icon nzType="file-done" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/info-content"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.MANAGEMENT'|translate}}</a></span>
          </li>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/info-chapter"
            [nzSelected]="infoSubmenu.chapterMenu">
            <i nz-icon nzType="file-text" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/info-chapter"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.CHAPTERS'|translate}}</a></span>
          </li>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/info-section"
            [nzSelected]="infoSubmenu.sectionMenu">
            <i nz-icon nzType="align-left" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/info-section"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.SECTIONS'|translate}}</a></span>
          </li>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/info-multimedia"
            [nzSelected]="infoSubmenu.multimediaMenu">
            <i nz-icon nzType="file-image" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/info-multimedia"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.MULTIMEDIA'|translate}}</a></span>
          </li>
        </ul>
      </li>
      <!--LOGS -->
      <li id="logs" routerLinkActive="ant-menu-item-selected" nz-menu-item [nzMatchRouter]="true">
        <a routerLink="/authenticated/logs" (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">
          <i nz-icon nzType="file-text" nzTheme="outline"></i>
          <span [style.display]="isCollapsed ? 'none': 'inline'">{{'MENU.LOG'|translate}}</span>
        </a>
      </li>
      <!--ABOUT -->
      <li nz-submenu [nzTitle]="isCollapsed ? '' : ( 'MENU.ABOUT' | translate )" nzIcon="info-circle"
        [nzOpen]="aboutSubmenu.active">
        <ul>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/about/legenda"
            [nzSelected]="aboutSubmenu.legendaMenu">
            <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/about/legenda"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.LEGEND'|translate}}</a></span>
          </li>
          <li nz-menu-item [nzMatchRouterExact]="true" routerLink="/authenticated/about/versions"
            [nzSelected]="aboutSubmenu.versionMenu">
            <i nz-icon nzType="tag" nzTheme="outline"></i>
            <span><a routerLink="/authenticated/about/versions"
                (click)="this.AS.isMobile ? isCollapsed = !isCollapsed : null">{{'MENU.VERSIONS_LOG'|translate}}</a></span>
          </li>

        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
          </span>
        </div>



        <div class="version-container">
          <div class="pr-16">
            <nz-select [(ngModel)]="selectedLanguage" (ngModelChange)="onLanguageChange($event)"
              nzPlaceHolder="Seleziona lingua" [nzCustomTemplate]="langTemplate" nzBorderless>
              <nz-option *ngFor="let language of languages" [nzCustomContent]="true" [nzValue]="language.code"
                [nzLabel]="language.label | translate">
                <span class="pr-8">
                  <span class="fi" [class]="'fi-'+language.flag"></span>
                </span>
                {{ language.label | translate }}
              </nz-option>
            </nz-select>
            <ng-template #langTemplate>
              <ng-container *ngIf="selectedLanguageItem$| async as selected">
                <div nz-row>
                  <span nz-col class="pr-8">
                    <span class="fi" [class]="'fi-'+selected.flag"></span>
                  </span>
                  <span nz-col>
                    {{ selected.label | translate }}
                  </span>
                </div>
              </ng-container>
            </ng-template>
          </div>

          <div class="version" class="pr-16" [ngClass]="{'totally-hidden': AS.isMobile && !isCollapsed}">
            <div>YourFill Backoffice</div>
            <div><b>{{'ABOUT.VERSIONS_LOG.VERSION'|translate}} {{ version }}</b></div>
          </div>

          <!-- <button nz-button nzType="default" (click)="logout()">
            <i nz-icon nzType="logout" nzTheme="outline"></i>
          </button> -->
          <!-- <button nz-button nzType="primary" nzShape="circle" nz-popover nzPopoverTrigger="hover" nzPopoverTitle="Title" nzPopoverContent="Content" nzPopoverPlacement="bottomRight">A</button> -->
          <div class="yf-pointer" *ngIf="(auth.user$|async) as avatarUser">
            <nz-avatar nzText={{avatarInitials$|async}} class="avatar" nz-popover nzPopoverTrigger="hover"
              [nzPopoverTitle]="titleTemplate" [nzPopoverContent]="contentTemplate"
              nzPopoverPlacement="bottomRight"></nz-avatar>
            <ng-template #titleTemplate>
              <div style="text-align: center;">
                {{avatarUser.email}}
              </div>
            </ng-template>
            <ng-template #contentTemplate>
              <div nz-row nzJustify="space-around">
                <div nz-col nzFlex="auto"></div>
                <div nz-col>
                  <nz-avatar nzText={{avatarInitials$|async}} class="avatar" nzSize="large"></nz-avatar>
                </div>
                <div nz-col nzFlex="auto"></div>
              </div>
              <div nz-row nzJustify="space-around">
                <div nz-col nzFlex="auto"></div>
                <div nz-col>
                  <h2>
                    {{'AVATAR.HI'|translate}} {{avatarUser.name}}!
                  </h2>
                </div>
                <div nz-col nzFlex="auto"></div>
              </div>
              <div nz-row nzJustify="space-around">
                <button nz-button nzType="primary" nzSize="default" nzShape="round" routerLink="avatarDetail"
                  class="no-round-right mr-2">
                  <i nz-icon nzType="edit" nzTheme="outline"></i>
                  {{'AVATAR.MY_PROFILE'|translate}}
                </button>
                <button nz-button nzType="primary" nzSize="default" nzShape="round" class="no-round-left ml-2"
                  (click)=logout()>
                  <i nz-icon nzType="logout" nzTheme="outline"></i>
                  {{'AVATAR.LOGOUT'|translate}}
                </button>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content" (click)="this.AS.isMobile && !isCollapsed ? isCollapsed = true : null">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>