<ng-container *ngFor="let item of this.features | keyvalue" [ngSwitch]="item.key" >

    <ng-container *ngSwitchCase="'isAdmin'">
        <div *ngIf="item.value" class="yf-inline-block p-4">
            <span nz-icon nzType="safety-certificate" nzTheme="twotone" nzTooltipTitle="{{'NZTOOLTIPTITLE.ADMIN_USER'|translate}}" nzTooltipPlacement="top" nz-tooltip></span>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="'disabled'">
        <div *ngIf="item.value" class="yf-inline-block p-4">
            <span nz-icon nzType="stop" nzTheme="twotone" [nzTwotoneColor]="'#F32013'" nzTooltipTitle="{{'NZTOOLTIPTITLE.DISABLED_USER'|translate}}" nzTooltipPlacement="top" nz-tooltip></span>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ccType'">
        <div *ngIf="item.value" class="yf-inline-block p-4">
            <span nzTooltipTitle="{{'NZTOOLTIPTITLE.AUTHORIZED_CARD'|translate}}" nzTooltipPlacement="top" nz-tooltip>
              <img [src]="_utilityFuncs.getLogoByCdcType(item.value.toString())" class="cc-img">
            </span>
        </div>
    </ng-container>

</ng-container>


