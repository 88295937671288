<nz-table #basicTable [nzData]="listOfData" [nzPageSize]="10">
  <thead>
    <tr>
      <th *ngFor="let th of thHeader">{{ th }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <td *ngFor="let cellData of tdRowData" [routerLink]="['','authenticated', path, data[idName] ]"
        class="yf-pointer">
        {{data[cellData]}}
      </td>
    </tr>
  </tbody>
</nz-table>
